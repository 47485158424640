/* eslint-disable no-unused-vars */
import { DateTime } from 'luxon'

export enum SegmentTypeEnum {
  NONE = 'NONE',
  WAITING_ROOM = 'WAITING_ROOM',
  OPEN_DISCUSSION = 'OPEN_DISCUSSION',
  VIDEO_MODE = 'VIDEO_MODE',
}

export enum SegmentDefaultNameEnum {
  NONE = 'None',
  WAITING_ROOM = 'Waiting Room',
  OPEN_DISCUSSION = 'Open Discussion',
  VIDEO_MODE = 'Video Mode',
}

export enum ScriptSegmentDefaultNameEnum {
  OPEN_DISCUSSION = 'Discussion',
  VIDEO_MODE = 'Video',
}

export enum RoomTypeEnum {
  WAITING_ROOM = 'WAITING_ROOM',
  TECHNICAL_DIFFICULTIES_ROOM = 'TECHNICAL_DIFFICULTIES_ROOM',
}

export const getScriptSegmentDefaultName = (segmentType: SegmentTypeEnum) => {
  switch (segmentType) {
    case SegmentTypeEnum.OPEN_DISCUSSION:
      return ScriptSegmentDefaultNameEnum.OPEN_DISCUSSION
    case SegmentTypeEnum.VIDEO_MODE:
      return ScriptSegmentDefaultNameEnum.VIDEO_MODE
  }
}

export const getSegmentDefaultName = (segmentType: SegmentTypeEnum) => {
  switch (segmentType) {
    case SegmentTypeEnum.NONE:
      return SegmentDefaultNameEnum.NONE
    case SegmentTypeEnum.WAITING_ROOM:
      return SegmentDefaultNameEnum.WAITING_ROOM
    case SegmentTypeEnum.OPEN_DISCUSSION:
      return SegmentDefaultNameEnum.OPEN_DISCUSSION
    case SegmentTypeEnum.VIDEO_MODE:
      return SegmentDefaultNameEnum.VIDEO_MODE
  }
}
export enum EventTypes {
  START_SEGMENT = 'START_SEGMENT',
  PARTICIPANT_JOINED = 'PARTICIPANT_JOINED',
  PARTICIPANT_LEFT = 'PARTICIPANT_LEFT',
  DESIGNATE_SPEAKER = 'DESIGNATE_SPEAKER',
  FINISH_SPEAKER = 'FINISH_SPEAKER',
  TEXT_TO_SPEECH = 'TEXT_TO_SPEECH',
  SURVEY = 'SURVEY',
  SURVEY_ANSWER = 'SURVEY_ANSWER',
  FINISH_SESSION = 'FINISH_SESSION',
  RAISE_HAND = 'RAISE_HAND',
  LOWER_HAND = 'LOWER_HAND',
  GROUP_FEEDBACK = 'GROUP_FEEDBACK',
  SOFI_ONLY_FEEDBACK = 'SOFI_ONLY_FEEDBACK',
  MUTE_PARTICIPANT = 'MUTE_PARTICIPANT',
  TECHNICAL_DIFFICULTIES = 'TECHNICAL_DIFFICULTIES',
  PARTICIPANT_FINISHED_VIDEO_STREAM = 'PARTICIPANT_FINISHED_VIDEO_STREAM',
}

export enum GroupFeedbackTypes {
  ON_POINT = 'ON_POINT',
  FUNNY = 'FUNNY',
  HURTFUL = 'HURTFUL',
  UNCLEAR = 'UNCLEAR',
  AGREED = 'AGREED',
  HAHA = 'HAHA',
  RESPECT = 'RESPECT',
  HUH = 'HUH',
  FEEL_YA = 'FEEL_YA',
}

export enum SofiOnlyFeedbackTypes {
  UNSAFE = 'UNSAFE',
  BORING = 'BORING',
  FEELS_FAKE = 'FEELS_FAKE',
  OFFTOPIC = 'OFFTOPIC',
}

export enum AgoraEvent {
  USER_JOINED = 'USER_JOINED',
  USER_LEFT = 'USER_LEFT',
  USER_MUTED = 'USER_MUTED',
}

export type SegmentType = {
  segmentId?: string
  segmentName: string
  segmentType: SegmentTypeEnum
  createdAt: DateTime
}

export const SegmentDefault = {
  segmentId: '',
  segmentName: SegmentDefaultNameEnum.NONE,
  segmentType: SegmentTypeEnum.NONE,
  createdAt: DateTime.now(),
}

export enum Preferences {
  RAISE_HAND = 'raiseHand',
  FEEDBACK_BAR = 'feedbackBar',
  SPEAKER_DEFAULT_SECONDS = 'speakerDefaultSeconds',
}

export enum SnackbarToastType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum SnackbarPage {
  SESSIONS = 'sessions',
  CREATE = 'create',
  EDIT = 'edit',
}

export const PreferencesDefault = {
  [Preferences.RAISE_HAND]: false,
}

export const SegmentPreferencesDefault = {
  [Preferences.RAISE_HAND]: true,
  [Preferences.FEEDBACK_BAR]: true,
  [Preferences.SPEAKER_DEFAULT_SECONDS]: '120',
}

export type AgoraTokenResponse = {
  token: string
  channelName: string
  participantAgoraId: string
}

export enum MicState {
  OFF = 'OFF',
  ON = 'ON',
  DISABLED = 'DISABLED',
}

export enum AgoraConnectionState {
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  RECONNECTING = 'RECONNECTING',
  CONNECTED = 'CONNECTED',
  DISCONNECTING = 'DISCONNECTING',
}

export type RegisterEventProps = {
  eventTypeName: string
  metadata: string
}

export function isStartSegment(event: { event_type: { name: string } }) {
  return event.event_type.name === EventTypes.START_SEGMENT
}

export function isTechnicalDifficulties(event: {
  event_type: { name: string }
}) {
  return event.event_type.name === EventTypes.TECHNICAL_DIFFICULTIES
}

export function isSpeakerEvent(event: { event_type: { name: string } }) {
  return (
    event.event_type.name === EventTypes.FINISH_SPEAKER ||
    event.event_type.name === EventTypes.DESIGNATE_SPEAKER ||
    event.event_type.name === EventTypes.MUTE_PARTICIPANT
  )
}

export function isVideoStreamFinishedEvent(event: {
  event_type: { name: string }
}) {
  return event.event_type.name === EventTypes.PARTICIPANT_FINISHED_VIDEO_STREAM
}

export function isFeedbackEvent(event: { event_type: { name: string } }) {
  return event.event_type.name === EventTypes.GROUP_FEEDBACK
}

export function isHandEvent(event: { event_type: { name: string } }) {
  return (
    event.event_type.name === EventTypes.RAISE_HAND ||
    event.event_type.name === EventTypes.LOWER_HAND
  )
}

export function isJoinedLeftEvent(event: { event_type: { name: string } }) {
  return (
    event.event_type.name === EventTypes.PARTICIPANT_JOINED ||
    event.event_type.name === EventTypes.PARTICIPANT_LEFT
  )
}

type GenerateFeedbackPhraseParams = {
  feedbackType: string
}

export enum RoomMessage {
  WR_START = 'You are currently on the waiting room, once all the participants and conductor are ready we will start again with the session',
  WR_RESUME = 'We will now proceed with the session, as everyone is ready',
  TD_START = 'Sorry, we are currently dealing with some technical difficulties, the session will be resumed shortly',
  TD_RESUME = 'We will now proceed with the session, as the technical difficulties have been fixed',
}

export const getTodayDateWithoutTime = () => {
  const nowDate = new Date()
  return (
    nowDate.getFullYear() +
    '-' +
    (nowDate.getMonth() + 1) +
    '-' +
    nowDate.getDate()
  )
}

export const getCurrentDateWithoutTime = (plusDays: number) => {
  return DateTime.now()
    .set({ hour: 0, minute: 0, second: 0 })
    .plus({ days: plusDays })
}

export const getSecondsDifference = (dt2: Date, dt1: Date) => {
  const diff = (dt2.getTime() - dt1.getTime()) / 1000
  return Math.abs(Math.round(diff))
}

export const sessionDateFilters = {
  ALL: {
    key: 'ALL',
    filter: {
      deleted_at: { _is_null: true },
      is_template: { _eq: false }
    },
  },
  TODAY: {
    key: 'TODAY',
    filter: {
      date_time: {
        _gte: getCurrentDateWithoutTime(0).toFormat('yyyy-MM-dd'),
        _lte: getCurrentDateWithoutTime(1).toFormat('yyyy-MM-dd'),
      },
      deleted_at: { _is_null: true },
      is_template: { _eq: false },
    },
  },
  TEMPLATES: {
    key: 'TEMPLATES',
    filter: {
      deleted_at: { _is_null: true },
      is_template: { _eq: true },
    },
  },
}

export type ISessionFormInput = {
  sessionName?: string
  legacy_program?: string
  companyId?: string
  date?: DateTime
  time?: DateTime
  timezone?: string
  is_template?: string
  template_id?: string
}

export type Timezone = {
  id: string
  value: string
}

// TODO: This hardcoded timezones are affected by daylight savings so updating them periodically is necessary. Ideally we should migrate this to a scalable alternative.
export const timezones: Array<Timezone> = [
  { id: 'UTC', value: 'UTC+0' },
  { id: 'AST', value: 'UTC-3' },
  { id: 'EST', value: 'UTC-4' },
  { id: 'CST', value: 'UTC-5' },
  { id: 'MST', value: 'UTC-6' },
  { id: 'PST', value: 'UTC-7' },
]

export function generateFeedbackPhraseVerb({
  feedbackType,
}: GenerateFeedbackPhraseParams): string {
  switch (feedbackType) {
    // Group
    case GroupFeedbackTypes.ON_POINT:
    case GroupFeedbackTypes.FUNNY:
    case GroupFeedbackTypes.HURTFUL:
    case GroupFeedbackTypes.UNCLEAR:
    case GroupFeedbackTypes.HAHA:
    case GroupFeedbackTypes.HUH:
      return `thinks`
    case GroupFeedbackTypes.AGREED:
      return `agrees with`
    case GroupFeedbackTypes.RESPECT:
      return `gave`
    case GroupFeedbackTypes.FEEL_YA:
      return `says feel ya to`

    // Sofi Only Feedback
    case SofiOnlyFeedbackTypes.UNSAFE:
      return `feels the conversation is unsafe during`

    case SofiOnlyFeedbackTypes.BORING:
      return `feels the conversation is boring during`

    case SofiOnlyFeedbackTypes.FEELS_FAKE:
      return `feels the conversation is fake during`

    case SofiOnlyFeedbackTypes.OFFTOPIC:
      return `feels the conversation is off-topic during`
  }
}

export function generateFeedbackPhrasePredicate({
  feedbackType,
}: GenerateFeedbackPhraseParams): string {
  switch (feedbackType) {
    // Group
    case GroupFeedbackTypes.ON_POINT:
      return `'s comment is on point.`
    case GroupFeedbackTypes.FUNNY:
    case GroupFeedbackTypes.HAHA:
      return `'s comment is funny.`
    case GroupFeedbackTypes.HURTFUL:
      return `'s comment is hurtful.`
    case GroupFeedbackTypes.UNCLEAR:
    case GroupFeedbackTypes.HUH:
      return `'s comment is unclear.`
    case GroupFeedbackTypes.AGREED:
      return `'s comment.`
    case GroupFeedbackTypes.RESPECT:
      return ` some respect.`
    case GroupFeedbackTypes.FEEL_YA:
      return `.`

    // Sofi Only Feedback
    case SofiOnlyFeedbackTypes.UNSAFE:
    case SofiOnlyFeedbackTypes.BORING:
    case SofiOnlyFeedbackTypes.FEELS_FAKE:
    case SofiOnlyFeedbackTypes.OFFTOPIC:
      return `'s comment.`
  }
}

export function normalizeString(value: string | null): string | null {
  if (value === null || value.trim() === '') {
    return null;
  }
  return value;
}
